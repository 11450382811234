//Copyright © Northstar Travel Media, LLC. All Rights Reserved - code may not be reused in whole or in part without written consent- removal of this section consitutes a copyright violation


function serviceProxy(serviceUrl) {
    var _I = this;
    this.serviceUrl = serviceUrl;
    this.invoke = function(method, data, callback, error) {
        var json = JSON.stringify(data);
        var url = _I.serviceUrl + method;
        $.ajax({
            url: url,
            data: json,
            type: "POST",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function(data) {
                if (data.d) { data = data.d }
                if (!callback) return; callback(data); return;
            },
            error: function(err) { if (!error) return; error(err); return; }
        });
     }
 }
function AjaxFailed(result) { if (result.status == 0) { return; } alert(result.status + ' ' + result.statusText); }
DynaProxy = new serviceProxy("/Dyna.asmx/");
        
$(function () {
    $(document).on("click", ".show-more", function () {
      var req = new Object;
        var e = $(this);
        req.PageKey = e.attr("_p");
        req.WidgetId = e.attr("_wid");
        req.PageNumber = e.attr("_n");
        req.User1 = $("#event-type").val();       
        var DTO = { 'req': req }
        DynaProxy.invoke("PageContentList", DTO, function (data) {
            var e = $(".show-more").parents(".mt-4")
            $(e).before(data);
            $(e).remove();           
        }, AjaxFailed);        
        return false;
    })
    
    $(document).on("change", "#event-type", function () {
     $(".show-more-container").css({ opacity: 0.3 }); 
     var req = new Object;
        var e = $(".show-more-container")                     ;
        req.PageKey = e.attr("_p");
        req.WidgetId = e.attr("_wid");
        req.User1 = $("#event-type").val();
        req.PageNumber = 1;          
        var DTO = { 'req': req }
        DynaProxy.invoke("PageContentList", DTO, function (data) {              
            $(".show-more-container").html(data).css('opacity', '');  	   
          
        }, AjaxFailed);        
        return false;
    })    
});


